<template>
  <v-row justify="center">
    <AGDialog
      v-model="show"
      title="Confirmação de leitura"
      primary-button-text="Concordo"
      tertiary-button-text="Manter como não lido"
      @tertiaryButtonClick="show = false"
      @primaryButtonClick="concordo()"
    >
      Ao clicar em concordo a mensagem será marcada como lida na Agnes e no e-CAC.
    </AGDialog>
    <ModalMessageFull ref="modalMessageFull" :message="item" :details="details" />
  </v-row>
</template>
<script>
import IntegraContadorService from '@/services/integraContadorService.js';

export default {
  name: 'ModalReadMessage',

  components: {
    ModalMessageFull: () => import('./ModalMessageFull.vue'),
    AGDialog: () => import('../../commons/AGDialog.vue'),
  },

  props: ['item'],

  data() {
    return {
      show: false,
      details: null,
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    open() {
      this.show = true;
    },

    concordo() {
      this.show = false;
      this.$emit('update');
      this.showMessagFull();
    },

    async showMessagFull() {
      try {
        const { data } = await IntegraContadorService.messages.getDetails(this.item);
        this.details = data;
        this.$emit('refresh');
      } catch (error) {
        console.log('Erro ao buscar detalhes da mensagem: ', error);
      }

      this.$refs.modalMessageFull.$emit('open');
    },

    close() {
      this.show = false;
    },
  },
};
</script>
