var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "AGDialog",
        {
          attrs: {
            title: "Confirmação de leitura",
            "primary-button-text": "Concordo",
            "tertiary-button-text": "Manter como não lido",
          },
          on: {
            tertiaryButtonClick: function ($event) {
              _vm.show = false
            },
            primaryButtonClick: function ($event) {
              return _vm.concordo()
            },
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _vm._v(
            " Ao clicar em concordo a mensagem será marcada como lida na Agnes e no e-CAC. "
          ),
        ]
      ),
      _c("ModalMessageFull", {
        ref: "modalMessageFull",
        attrs: { message: _vm.item, details: _vm.details },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }